<template>
  <v-row>
    <v-col cols="12" md="6">
      <h2>Información de empresa</h2>
      <v-container>
        <v-row>
          <v-col cols="12" class="pa-0">
            <v-text-field
              v-model="providerName"
              outlined
              label="Nombre de la empresa"
              color="secondary"
              :rules="nameRules"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-text-field
              v-model="providerPhone"
              outlined
              label="Teléfono"
              color="secondary"
              :rules="phoneRules"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-text-field
              v-model="providerEmail"
              outlined
              label="Correo electrónico"
              color="secondary"
              :rules="emailRules"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-text-field
              v-model="providerType"
              outlined
              label="Giro de la empresa"
              color="secondary"
              :rules="nameRules"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-text-field
              v-model="providerRFC"
              outlined
              label="RFC"
              color="secondary"
              :rules="rfcProviderRules"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-text-field
              v-model="providerState"
              outlined
              label="Estado"
              color="secondary"
              :rules="stateRules"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-text-field
              v-model="providerCity"
              outlined
              label="Ciudad"
              color="secondary"
              :rules="cityRules"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-text-field
              v-model="providerAdress"
              outlined
              label="Dirección"
              color="secondary"
              :rules="fillInput"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-text-field
              v-model="providerNeighbour"
              outlined
              label="Colonia"
              color="secondary"
              :rules="fillInput"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-text-field
              v-model="providerNumber"
              outlined
              label="Número"
              color="secondary"
              :rules="fillInput"
            >
            </v-text-field>
          </v-col>
          <v-col cols="12" class="pa-0">
            <v-text-field
              v-model="providerPostalCode"
              outlined
              label="Código postal"
              color="secondary"
              :rules="codeRules"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
    <v-col cols="12" md="6">
      <h2 class="ml-md-5">Foto de perfil empresarial</h2>
      <Photo-upload align="center" />
      <h2 class="ml-md-5 my-4">Identificación oficial del patrón</h2>
      <File-upload align="center" :default-button-text="btnText" />
    </v-col>
    <v-col cols="12">
      <h2 class="ml-md-5">
        Sube tus mejores fotos para mostrarlas en tu perfil
      </h2>
      <Mult-image-upload
        align="center"
        :max="25"
        upload-msg="Haz click o arrastra una imagen al recuadro"
        max-error="Solamente puede subir hasta 1 imagen."
        file-error="Solamente se aceptan imágenes (.png o .jpg)"
      />
    </v-col>
  </v-row>
</template>

<script>
import {
  nameRules,
  cityRules,
  emailRules,
  phoneRules,
  rfcProviderRules,
  stateRules,
  fillInput,
  codeRules,
} from "../../../Utils/rules";
import FileUpload from "../../Shared/FileUpload.vue";
import MultImageUpload from "../../Shared/MultImageUpload.vue";
import PhotoUpload from "../../Shared/PhotoUpload.vue";

export default {
  components: { PhotoUpload, FileUpload, MultImageUpload },
  data: () => ({
    btnText: "+ Subir archivo (.PNG, .JPEG, .PDF)",
    providerName: "",
    providerPhone: "",
    providerEmail: "",
    providerType: "",
    providerState: "",
    providerNeighbour: "",
    providerNumber: "",
    providerRFC: "",
    providerCity: "",
    providerAdress: "",
    providerPostalCode: "",
    nameRules: nameRules,
    cityRules: cityRules,
    stateRules: stateRules,
    emailRules: emailRules,
    phoneRules: phoneRules,
    rfcProviderRules: rfcProviderRules,
    fillInput: fillInput,
    codeRules: codeRules,
  }),
  computed: {},
  methods: {},
};
</script>

<style scoped></style>
