<template>
  <v-container>
    <v-data-table
      class="text-body-1"
      :headers="headers"
      :items="filterStatus"
      item-key="id"
      :hide-default-footer="true"
      disable-pagination
      :header-props="{
        'sort-by-text': 'ordenar por',
      }"
      :no-results-text="emptyResultFilter"
    >
      <template v-slot:[`item.name`]="{ item }">
        <div
          color="transparent"
          class="text-decoration-underline tableFontChange"
        >
          <router-link
            v-if="
              item.userNameType === 'Cliente' ||
              item.userNameType === 'Desactivada'
            "
            class="black--text"
            :to="`/admin/cuentas/cliente`"
            >{{ item.name }}</router-link
          >
          <router-link
            v-else-if="item.userNameType === 'Proveedor'"
            class="black--text"
            :to="`/admin/cuentas/proveedor`"
            >{{ item.name }}</router-link
          >
        </div>
      </template>
      <template v-slot:no-data> No hay usuarios registrados. </template>
    </v-data-table>
  </v-container>
</template>

<script>
export default {
  props: {
    nameFilter: {
      type: String,
      default: "",
    },
    filterStatus: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data: () => ({
    emptyResultFilter: "No se encontraron usuarios con ese nombre.",
  }),
  computed: {
    headers() {
      return [
        {
          text: "Nombre",
          align: "start",
          sortable: false,
          value: "name",
          class: "text-h5 black--text font-weight-bold",
          filter: (f) => {
            return (f + "")
              .toLowerCase()
              .includes(this["nameFilter"].toLowerCase());
          },
        },
        {
          text: "Usuario",
          value: "userNameType",
          class: "text-h5 black--text font-weight-bold",
        },
        {
          text: "Fecha de registro",
          value: "registerDate",
          class: "text-h5 black--text font-weight-bold",
        },
        {
          text: "Telefono",
          value: "phoneNumber",
          class: "text-h5 black--text font-weight-bold",
        },
        {
          text: "Correo",
          value: "email",
          class: "text-h5 black--text font-weight-bold",
        },
      ];
    },
  },
  methods: {
    getColor(status) {
      if (status === "Approved") {
        return "success";
      } else if (status === "Denied") {
        return "error";
      } else {
        return "warning";
      }
    },
  },
};
</script>

<style scoped>
.v-data-table >>> td,
.tableFontChange {
  font-size: 18px !important;
}
</style>
