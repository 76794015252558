<template>
  <v-row>
    <v-col cols="12" md="6">
      <h2>Información general</h2>
      <v-container>
        <v-row>
          <v-col cols="12" class="pa-0">
            <v-text-field
              v-model="legalName"
              outlined
              label="Nombre"
              color="secondary"
              :rules="nameRules"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pa-0">
            <v-text-field
              v-model="userEmail"
              type="email"
              outlined
              label="Correo electrónico"
              color="secondary"
              :rules="emailRules"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pa-0">
            <v-text-field
              v-model="userPhone"
              outlined
              label="Télefono"
              color="secondary"
              :rules="phoneRules"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pa-0">
            <v-text-field
              v-model="userCity"
              outlined
              label="Ciudad"
              color="secondary"
              :rules="cityRules"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pa-0">
            <v-text-field
              v-model="newPassword"
              outlined
              label="Contraseña"
              color="secondary"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="passwordRules"
              @click:append="showPassword = !showPassword"
            >
            </v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="pa-0">
            <v-text-field
              v-model="confirmPassword"
              outlined
              label="Confirmar Contraseña"
              color="secondary"
              :type="showPassword ? 'text' : 'password'"
              :append-icon="showPassword ? 'mdi-eye' : 'mdi-eye-off'"
              :rules="confirmPasswordRules.concat(passwordConfirmationRule)"
              @click:append="showPassword = !showPassword"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
    <v-col cols="12" md="6">
      <h2 class="ml-md-5">Foto de perfil</h2>
      <Photo-upload align="center" />
    </v-col>
  </v-row>
</template>

<script>
import { mapState } from "vuex";
import {
  nameRules,
  cityRules,
  emailRules,
  phoneRules,
  passwordRules,
  confirmPasswordRules,
} from "../../../Utils/rules";
import PhotoUpload from "../../Shared/PhotoUpload.vue";

export default {
  components: { PhotoUpload },
  data: () => ({
    legalName: "",
    userName: "",
    userEmail: "",
    userPhone: "",
    userCity: "",
    newPassword: "",
    confirmPassword: "",
    showPassword: false,
    nameRules: nameRules,
    cityRules: cityRules,
    emailRules: emailRules,
    phoneRules: phoneRules,
    passwordRules: passwordRules,
    confirmPasswordRules: confirmPasswordRules,
  }),
  computed: mapState({
    passwordConfirmationRule() {
      return () =>
        this.newPassword === this.confirmNewPassword ||
        "Las contraseñas deben coincidir";
    },
    user(state) {
      return state.user;
    },
  }),
  methods: {},
};
</script>

<style scoped></style>
