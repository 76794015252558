var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-data-table',{staticClass:"text-body-1",attrs:{"headers":_vm.headers,"items":_vm.filterStatus,"item-key":"id","hide-default-footer":true,"disable-pagination":"","header-props":{
      'sort-by-text': 'ordenar por',
    },"no-results-text":_vm.emptyResultFilter},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"text-decoration-underline tableFontChange",attrs:{"color":"transparent"}},[(
            item.userNameType === 'Cliente' ||
            item.userNameType === 'Desactivada'
          )?_c('router-link',{staticClass:"black--text",attrs:{"to":"/admin/cuentas/cliente"}},[_vm._v(_vm._s(item.name))]):(item.userNameType === 'Proveedor')?_c('router-link',{staticClass:"black--text",attrs:{"to":"/admin/cuentas/proveedor"}},[_vm._v(_vm._s(item.name))]):_vm._e()],1)]}},{key:"no-data",fn:function(){return [_vm._v(" No hay usuarios registrados. ")]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }