<template>
  <v-container fluid ma-0 pa-0>
    <div class="purple-gradient fill-height">
      <Navbar @handleStatusChange="handleCreateShow()" />
    </div>

    <v-container v-if="createAccountShow == false" class="pb-0 body-container">
      <v-container class="pb-0">
        <v-row>
          <v-col cols="12" sm="7" md="10">
            <h1 class="mt-10">Cuentas</h1>
          </v-col>
          <v-col cols="12" sm="5" md="2">
            <div align="center">
              <Button
                text="Cuenta nueva"
                class="mt-sm-10 mt-md-10"
                aria-label="Cuenta nueva"
                @event="handleCreateShow()"
              />
            </div>
          </v-col>
          <v-col cols="12" sm="10">
            <v-card class="elevation-0">
              <v-tabs
                v-model="filterResult"
                background-color="white"
                show-arrows
              >
                <v-tabs-slider color="primary"></v-tabs-slider>
                <v-tab v-for="status in buttonTabFilter" :key="status.key">
                  {{ status.name }}
                </v-tab>
              </v-tabs>
            </v-card>
          </v-col>
        </v-row>
      </v-container>

      <v-divider></v-divider>

      <v-row class="mt-3">
        <v-col cols="12" md="4">
          <v-text-field
            v-model="nameFilter"
            outlined
            dense
            class="rounded-lg"
            color="primary"
            label="Nombre del usuario"
            placeholder="¿Cuál es el nombre del usuario?"
          >
          </v-text-field>
        </v-col>

        <v-col cols="12" md="4">
          <v-select
            label="Ordenar por"
            :items="searchSelect"
            item-text="state"
            item-value="abbr"
            class="rounded-lg"
            color="primary"
            outlined
            clearable
            dense
          ></v-select>
        </v-col>
        <v-col cols="12">
          <v-container
            ><Account-table
              :filter-status="filterStatus"
              :name-filter="nameFilter"
          /></v-container>
        </v-col>

        <v-col cols="12"> </v-col>
      </v-row>
    </v-container>
    <v-container
      v-else-if="createAccountShow == true"
      class="pb-0 body-container"
    >
      <v-container class="pb-0 mt-sm-10 mt-md-10">
        <v-row>
          <v-col cols="12" sm="7" md="8">
            <h1 class="align-center align-sm-start ml-3">Cuenta nueva</h1>
          </v-col>
          <v-col cols="12" sm="5" md="4">
            <v-radio-group
              v-model="radioGroup"
              mandatory
              row
              class="align-center align-sm-end"
            >
              <v-radio
                label="cliente"
                justify="right"
                value="client"
                @click="createProvider = false"
              />
              <v-radio
                label="Proveedor"
                justify="right"
                value="provider"
                @click="createProvider = true"
              />
            </v-radio-group>
          </v-col>
          <v-col cols="12">
            <v-container><Client-acc-creation /></v-container>
          </v-col>
          <v-col v-if="createProvider === true" cols="12">
            <v-container><Provider-acc-creation /></v-container>
          </v-col>
          <v-container align="end">
            <v-row>
              <v-col cols="0" sm="8"></v-col>
              <v-col cols="12" sm="2">
                <Button
                  text="Cancelar"
                  class="mb-3"
                  aria-label="Cancelar"
                  @event="handleCancelCreate()"
                />
              </v-col>
              <v-col cols="12" sm="2">
                <Button
                  text="Enviar"
                  class="ml-sm-0 ml-xs-3"
                  aria-label="Enviar"
                  @event="handleCancelCreate()"
                />
              </v-col>
            </v-row>
          </v-container>
        </v-row>
      </v-container>
    </v-container>

    <Footer class="mt-12" />
  </v-container>
</template>

<script>
import Navbar from "@/components/Admin/Shared/Navbar.vue";
import Button from "@/components/Shared/Button.vue";
import AccountTable from "@/components/Admin/Accounts/AccountTable.vue";
import ClientAccCreation from "@/components/Admin/Accounts/ClientAccCreation.vue";
import ProviderAccCreation from "@/components/Admin/Accounts/ProviderAccCreation.vue";
import Footer from "@/components/Shared/Footer.vue";

export default {
  components: {
    Navbar,
    Button,
    AccountTable,
    ClientAccCreation,
    ProviderAccCreation,
    Footer,
  },
  data: () => ({
    filterResult: 0,
    nameFilter: "",
    radioGroup: 1,
    createAccountShow: false,
    createProvider: false,
    searchSelect: ["Más reciente", "Más antiguo"],
    buttonTabFilter: [
      { name: "Todos", value: "all" },
      { name: "Clientes", value: "client" },
      { name: "Proveedores", value: "provider" },
      { name: "Desactivadas", value: "deactivated" },
    ],
    accounts: [
      {
        id: "1",
        name: "Nombre1",
        userNameType: "Cliente",
        nameType: "client",
        phoneNumber: "123 456 789 1011",
        email: "invitado@invitado.com",
        registerDate: "11/11/0000",
      },
      {
        id: "2",
        name: "Nombre2",
        userNameType: "Proveedor",
        nameType: "provider",
        phoneNumber: "123 456 789 1011",
        email: "invitado@invitado.com",
        registerDate: "11/11/0000",
      },
      {
        id: "3",
        name: "Nombre3",
        userNameType: "Proveedor",
        nameType: "provider",
        phoneNumber: "123 456 789 1011",
        email: "invitado@invitado.com",
        registerDate: "11/11/0000",
      },
      {
        id: "4",
        name: "Nombre4",
        userNameType: "Desactivada",
        nameType: "deactivated",
        phoneNumber: "123 456 789 1011",
        email: "invitado@invitado.com",
        registerDate: "11/11/0000",
      },
      {
        id: "5",
        name: "Nombre5",
        userNameType: "Cliente",
        nameType: "client",
        phoneNumber: "123 456 789 1011",
        email: "invitado@invitado.com",
        registerDate: "11/11/0000",
      },
      {
        id: "6",
        name: "Nombre6",
        userNameType: "Proveedor",
        nameType: "provider",
        phoneNumber: "123 456 789 1011",
        email: "invitado@invitado.com",
        registerDate: "11/11/0000",
      },
      {
        id: "7",
        name: "Nombre7",
        userNameType: "Cliente",
        nameType: "client",
        phoneNumber: "123 456 789 1011",
        email: "invitado@invitado.com",
        registerDate: "11/11/0000",
      },
      {
        id: "8",
        name: "Nombre8",
        userNameType: "Desactivada",
        nameType: "deactivated",
        phoneNumber: "123 456 789 1011",
        email: "invitado@invitado.com",
        registerDate: "11/11/0000",
      },
    ],
  }),
  computed: {
    filterStatus() {
      return this.accounts.filter((acc) => {
        const accountStatus = acc.nameType;
        let buttonValue = "";
        if (this.filterResult == 1) {
          buttonValue = "client";
        } else if (this.filterResult == 2) {
          buttonValue = "provider";
        } else if (this.filterResult == 3) {
          buttonValue = "deactivated";
        }
        return accountStatus.includes(buttonValue);
      });
    },
  },
  methods: {
    handleCreateShow() {
      this.createAccountShow = !this.createAccountShow;
    },
    handleCancelCreate() {
      this.createAccountShow = false;
      window.scrollTo(0, 0);
    },
  },
};
</script>

<style scoped>
.purple-gradient {
  background-color: rgba(98, 37, 130, 255);
}
</style>
